import { createApp } from 'vue'
import router from './router/index'
import PrimeVue from 'primevue/config'
import { App } from '@madxnl/mrrabbit'

import ToastService from 'primevue/toastservice'
import { config } from '../mrrabbit'
import ConfirmationService from 'primevue/confirmationservice'

// Force deploy comment

// Make our components available anywhere
const app = createApp(App, {
  config,
})

const requireComponent = require.context('./', true, /[A-Z]\w+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const match = fileName.match(/(\w+)\.\w+$/)
  if (match) app.component(match[1], requireComponent(fileName).default)
})

app.use(router)
app.use(ToastService)
app.use(ConfirmationService)
app.use(PrimeVue)
app.mount('#app')
